import { SpartaAPI } from "classes/SpartaAPI";
import { SpartaDataFeed } from "classes/SpartaDataFeed";
import { recalculateWorkbook } from "commands/commands";
import { isFirstDayOfMonth } from "date-fns";
import { me } from "services";
import { getTimeUntilTomorrowUTC, getUTCNow } from "utils/helpers";
import { clearLocalStorage } from "utils/localStorage";
import { getToken, refreshToken } from "utils/token";

import { getCachedUser, removeCachedUser, setCachedUser } from "./cache/user";
import { trackProductsUpdated } from "./segment";

const ONE_SECOND = 1000;
const FIVE_SECONDS = ONE_SECOND * 5;
const ONE_MINUTE = ONE_SECOND * 60;
const FIVE_MINUTES = 5 * ONE_MINUTE;
const FIFTEEN_MINUTES = 15 * ONE_MINUTE;
const ONE_DAY = 24 * 60 * ONE_MINUTE;

const getUser = async () => {
  if (!!getToken() && (!global.Sparta.user || global.Sparta.revalidateUser)) {
    global.Sparta.setUser(await me());
    global.Sparta.revalidateUser = false;

    if (global.Sparta.user) setCachedUser(global.Sparta.user);
  } else if (!getToken() && global.Sparta.user) {
    global.Sparta.setUser(undefined);

    removeCachedUser();
  }
};

const validateStorage = ({ key, newValue }: StorageEvent) => {
  if ((key === "accessToken" || key === "refreshToken") && newValue === null) {
    clearLocalStorage();
  }
};

const trackUpdates = () => {
  if (!global.Sparta.user) return;

  const trackUpdateKey = "lastTrackUpdate";
  const now = Date.now();
  const lastTrackUpdate = parseInt(Office.context.document.settings.get(trackUpdateKey));

  if (isNaN(lastTrackUpdate) || now - lastTrackUpdate > ONE_DAY) {
    let observedProducts: string[] = [];

    if (global.Sparta.isNewWSEnabled()) {
      observedProducts = [
        ...global.CurvesDataFeed.getObservedProducts().map((productCode) =>
          global.Sparta.getLabelByProductCode(productCode)
        ),
        ...global.FuturesDataFeed.getObservedProducts().map((productCode) =>
          global.Sparta.getLabelByProductCode(productCode)
        ),
      ];
    } else {
      observedProducts = global.Sparta.getObservedProducts();
    }

    if (observedProducts.length > 0) {
      Office.context.document.settings.set(trackUpdateKey, now.toString());
      Office.context.document.settings.saveAsync();
      trackProductsUpdated(observedProducts);
    }
  }
};

let onMonthChangeTimeoutId: NodeJS.Timeout;
const monthChangeScheduler = () => {
  clearTimeout(onMonthChangeTimeoutId);

  const onUTCDayStarts = () => {
    console.log("new UTC day at", new Date());
    const UTCnow = getUTCNow();

    if (isFirstDayOfMonth(UTCnow)) {
      console.log("recalculate triggered due to new UTC month at", new Date());
      recalculateWorkbook();
    }

    monthChangeScheduler();
  };

  onMonthChangeTimeoutId = setTimeout(onUTCDayStarts, getTimeUntilTomorrowUTC());
};

(function () {
  Office.onReady(() => {
    const cachedUser = getCachedUser();

    global.Sparta = new SpartaAPI(cachedUser);

    global.CurvesDataFeed = new SpartaDataFeed("/excel/socket/websocket");
    global.FuturesDataFeed = new SpartaDataFeed("/futures/socket/excel/v2");

    global.Sparta.metadataSubject.subscribe(({ value: metadata, subscriptionId }) => {
      if (!Object.keys(metadata).length) return;

      recalculateWorkbook();
      global.Sparta.metadataSubject.unsubscribe(subscriptionId);
    });

    monthChangeScheduler();

    setInterval(getUser, ONE_SECOND);
    setInterval(() => global.Sparta.saveLatestValues(), FIFTEEN_MINUTES);
    setInterval(() => global.Sparta.updateSocketConnection(), FIVE_SECONDS);
    setInterval(refreshToken, FIVE_MINUTES);
    setInterval(trackUpdates, FIVE_MINUTES);

    window.addEventListener("storage", validateStorage);
  });
})();
