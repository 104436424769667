import { Entitlements, UserMe } from "services/auth/types";
import { LaunchDarklyMap } from "types";

export const isEntitlementEnabled = (user: UserMe, entitlement: Entitlements) =>
  user.license.excel.flag.value &&
  user.license.excel.entitlements.some(({ name, value }) => name === entitlement && value);

export const getFeatureFlagValue = <FeatureFlag extends keyof LaunchDarklyMap>(
  user: UserMe | undefined,
  flag: FeatureFlag
) => {
  if (!user) return undefined;

  const featureFlag = user?.launchDarklyFF.find(({ name }) => name === flag);

  return featureFlag?.value as LaunchDarklyMap[FeatureFlag] | undefined;
};
