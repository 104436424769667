import { FUNCTIONS_NAMESPACE } from "./constants";

type Param = string | number | boolean;

const injectParam = (param: Param) => {
  switch (typeof param) {
    case "number":
      return param;
    case "boolean":
      return param ? "TRUE" : "FALSE";
    default:
      return `"${param}"`;
  }
};

export const injectFormula = (formulaName: string, ...params: Param[]) =>
  `=${FUNCTIONS_NAMESPACE}.${formulaName.toUpperCase()}(${params.map(injectParam).toString()})`;

export const validateSheetName = (name: string) => {
  const result = name
    .replace(/[/\\?*:\[\]]/g, "")
    .replace(/^'|'$/g, "")
    .trim();

  return result.length ? result : "Sparta";
};

export const getExistingSheetNames = async () => {
  let names: string[] = [];

  await Excel.run(async (context) => {
    const sheets = context.workbook.worksheets;
    sheets.load("items/name");

    await context.sync();

    names = sheets.items.map((i) => i.name);
  });

  return names;
};

export const getSheetNameForPortfolio = (portfolioName: string, existingNames: string[]) => {
  let name = validateSheetName(portfolioName);
  let i = 1;

  while (existingNames.includes(name)) {
    name = `${validateSheetName(portfolioName)} (${i})`;
    i++;
  }

  return name;
};
