import { add, addDays, addMinutes, differenceInMilliseconds, format, parseISO, startOfDay } from "date-fns";
import { UserMe } from "services/auth/types";
import { Price } from "services/liveCurves/types";
import { Entries, Frequency } from "types";

import { getFeatureFlagValue } from "./user";

export const getKeyAndReduce = <Item, Key extends keyof Item, ResultItem = Item[Key]>(
  list: Item[],
  key: Key,
  fn?: (v: Item[Key]) => ResultItem
) =>
  [...list.reduce((res, { [key]: value }) => res.add(value), new Set<Item[Key]>())].map(
    (value) => (fn ? fn(value) : value) as ResultItem
  );

export const sortBy = <T, K extends keyof T>(array: T[], key: K, direction: "ascending" | "descending" = "ascending") =>
  array.sort((a, b) => {
    const x = a[key];
    const y = b[key];

    if (direction === "descending") {
      // eslint-disable-next-line no-nested-ternary
      return x < y ? 1 : x > y ? -1 : 0;
    }

    // eslint-disable-next-line no-nested-ternary
    return x < y ? -1 : x > y ? 1 : 0;
  });

/**
 * Look if the value includes the text or if the value includes each word of the text
 * @param value The string
 * @param text Text to search in the string
 * @returns True if there is a match
 */
export const filterByText = (value: string, text: string) =>
  value.toLowerCase().includes(text.toLowerCase()) ||
  text
    .toLowerCase()
    .split(" ")
    .every((word) => value.toLowerCase().includes(word));

export const getTimezoneOffset = () => new Date().getTimezoneOffset();

export const getUTCNow = () => addMinutes(Date.now(), getTimezoneOffset()).getTime();

export const getStartOfUTCTomorrow = () => startOfDay(addDays(getUTCNow(), 1)).getTime();

export const getTimeUntilTomorrowUTC = () => differenceInMilliseconds(getStartOfUTCTomorrow(), getUTCNow());

export const getTenorNameByCode = (code: string) => {
  const currentDate = getUTCNow();

  switch (code) {
    case "BOM":
      return format(currentDate, "MMM yy");
    case "BOQ":
      return `Q${format(currentDate, "Q yy")}`;
    case "BOY":
      return `Cal ${format(currentDate, "yy")}`;
    default: {
      const matchCode = /([A-Z]+)([0-9]*)/g.exec(code);

      if (!matchCode) {
        return "";
      }

      const { 1: type, 2: i } = matchCode;
      const iteration = parseInt(i, 10);

      switch (type) {
        case "M":
          return format(
            add(currentDate, {
              months: iteration,
            }),
            "MMM yy"
          );
        case "Q":
          return `Q${format(
            add(currentDate, {
              months: 3 * iteration,
            }),
            "Q yy"
          )}`;
        case "CL":
          return `Cal ${format(
            add(currentDate, {
              years: iteration,
            }),
            "yy"
          )}`;
        default:
          return currentDate.toString();
      }
    }
  }
};

export const getArrayOf = <T = number>(n: number, output?: (key: number) => T) =>
  [...Array(n || 0).keys()].map((key) => (output ? output(key) : key));

export const groupBy = <T, K extends keyof T>(list: T[], key: K): Record<string, T[]> =>
  list.reduce<Record<string, T[]>>(
    (hash, obj) => ({ ...hash, [`${obj[key]}`]: (hash[`${obj[key]}`] || []).concat(obj) }),
    {}
  );

// Formatting guide: https://support.microsoft.com/en-gb/office/review-guidelines-for-customizing-a-number-format-c0a1d1fa-d3f4-4018-96b7-9c9354dd99f5
export const getNumberFormatByDecimalPlaces = (decimalPlaces = 0) => {
  switch (decimalPlaces) {
    case 0:
      return "#,##0;-#,##0";
    default: {
      const decimalSubstring = getArrayOf(decimalPlaces, () => "0").join("");

      return `#,##0.${decimalSubstring};-#,##0.${decimalSubstring}`;
    }
  }
};

const FALLBACK_FREQUENCY_CONFIGURATION = {
  recommended: 2_000,
  idle: 5_000,
};

export const getFrequencyValue = (
  frequency: Exclude<Frequency, "live" | "pause">,
  user: UserMe | undefined,
  unit: "s" | "ms" = "ms"
): number => {
  let result = getFeatureFlagValue(user, `excel_${frequency}_frequency`) ?? FALLBACK_FREQUENCY_CONFIGURATION[frequency];

  if (unit === "s") {
    result = result / 1000;

    // Round to 1 decimal if necessary
    return Number.isInteger(result) ? result : Math.round(result * 10) / 10;
  }

  return result;
};

export const parseFutureValue = (price: string, type: Price["priceType"]) => {
  if (price) {
    switch (type) {
      case "Datetime":
        return format(parseISO(price), "dd MMM yyyy - HH:mm:ss");
      case "Number":
        return parseFloat(price);
      default:
        return null;
    }
  }

  return null;
};

export const getEntries = <T extends object>(obj: T) => Object.entries(obj) as Entries<T>;
