import { v4 as uuidv4 } from "uuid";

import { Subscription } from "./Subscription";

type CompareFn = <ValueType>(a: ValueType, b: ValueType) => boolean;

type SubjectOptions = {
  compareFn?: CompareFn;
  onEndObserving?: () => void;
  onStartObserving?: () => void;
};

const defaultCompareFn: CompareFn = (a, b) => a === b;

export class Subject<ValueType> {
  private value: ValueType;
  private subscriptions = new Map<string, Subscription<ValueType>>();

  private compareFn: CompareFn;
  private onEndObserving?: () => void;
  private onStartObserving?: () => void;

  public observed = false;

  constructor(value: ValueType, options?: SubjectOptions) {
    this.value = value;

    this.compareFn = options?.compareFn || defaultCompareFn;
    this.onEndObserving = options?.onEndObserving;
    this.onStartObserving = options?.onStartObserving;
  }

  public subscribe(callback: Subscription<ValueType>["callback"], updateAtStart = true): Subscription<ValueType> {
    const id = uuidv4();

    const subscription = new Subscription(id, callback, this);

    this.subscriptions.set(id, subscription);

    if (!this.observed) this.onStartObserving?.();
    this.observed = true;

    if (updateAtStart) {
      callback({ value: this.value, subscriptionId: id });
    }

    return subscription;
  }

  public unsubscribe(id: string): void {
    this.subscriptions.delete(id);

    const observed = this.isObserved();
    if (!observed && this.observed) this.onEndObserving?.();

    this.observed = observed;
  }

  public getValue(): ValueType {
    return this.value;
  }

  public next(value: ValueType): void {
    if (this.compareFn(this.value, value)) return;

    const prevValue = this.value;
    this.value = value;

    this.notifySubscribers(this.value, prevValue);
  }

  private isObserved(): boolean {
    return Array.from(this.subscriptions.values()).some((subscription) => subscription.isOpened());
  }

  private notifySubscribers(value: ValueType, prevValue?: ValueType): void {
    Array.from(this.subscriptions.values()).forEach((subscription) => subscription.notify(value, prevValue));
  }
}
