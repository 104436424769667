import { SPARTA_API } from "utils/axios";
import { parseFutureValue, sortBy } from "utils/helpers";
import { SPARTA_SOCKET, SPARTA_SOCKET_CORE } from "utils/socket";
import { getToken } from "utils/token";

import { trackDataUpdated } from "./segment";
import {
  FuturePriceDTO,
  Portfolio,
  PortfolioDetailDTO,
  PriceDTO,
  ProductMetadata,
  ProductsDTO,
  SocketData,
} from "./types";

let lastMessage: number;
const FIVE_MINUTES = 300_000;

export const getProductsMetadata = async () => {
  const { data } = await SPARTA_API().get<ProductMetadata[]>("/excel/livecurves/metadata");

  return data.reduce(
    (res, { code, ...rest }) => ({ ...res, [code]: { code, ...rest } }),
    {} as Record<string, ProductMetadata>
  );
};

export const getPortfolios = async () => {
  const { data } = await SPARTA_API().get<Portfolio[]>("/excel/livecurves/portfolios/selector");

  return sortBy(data, "order");
};

export const getPortfoliosDetails = async (ids: number[], obEnabled: boolean) => {
  if (ids.length > 0) {
    const { data } = await SPARTA_API({ useLambda: obEnabled }).get<PortfolioDetailDTO[]>(
      `/excel/livecurves/portfolios/detail?ids=${ids}`
    );
    return data;
  }
  return [];
};

export const getProducts = async (obEnabled: boolean) => {
  const { data } = await SPARTA_API({ useLambda: obEnabled }).get<ProductsDTO>("/excel/livecurves/products");

  return data;
};

/**
 * @deprecated Remove with the old socket (excel_new_ws)
 */
export const getPrices = async (codes: string[] = []) => {
  if (!codes.length) return [];

  const { data } = await SPARTA_API().post<PriceDTO[]>("/excel/livecurves/prices", {
    codes,
  });

  return data;
};

/**
 * @deprecated Remove with the old socket (excel_new_ws)
 */
export const getFuturePrices = async (codes: string[] = []) => {
  if (!codes.length) return [];

  const { data } = await SPARTA_API().post<FuturePriceDTO[]>("/futures/excel/prices", {
    codes,
  });

  return data;
};

/**
 * @deprecated Remove with the old socket (excel_new_ws)
 */
export const livePrices = () => {
  const socket = SPARTA_SOCKET("/excel/socket/livecurves?all=true");

  socket.onopen = () => {
    const token = getToken();

    if (token) {
      socket.onmessage = (ev) => {
        if (!lastMessage || Date.now() - lastMessage >= FIVE_MINUTES) {
          trackDataUpdated();
          lastMessage = Date.now();
        }

        const data = JSON.parse(ev.data) as SocketData;

        switch (data.type) {
          case "price_update":
            global.Sparta.registerUpdate(
              data.payload.code,
              data.payload.tenorName,
              data.payload.price ? parseFloat(data.payload.price) : null
            );
            break;
          case "batch_update":
            Object.entries(data.payload).forEach(([key, { price }]) =>
              global.Sparta.registerUpdateByKey(key, price ? parseFloat(price) : null)
            );
            break;
          case "heart_beat":
          default:
            break;
        }

        global.Sparta.socketReceived();
      };

      socket.send(token);
    }
  };

  return socket;
};

/**
 * @deprecated Remove with the old socket (excel_new_ws)
 */
export const futures = () => {
  const socket = SPARTA_SOCKET_CORE("/futures/socket/excel/v1");

  socket.onopen = () => {
    const token = getToken();

    if (token) {
      socket.onmessage = (ev) => {
        if (!lastMessage || Date.now() - lastMessage >= FIVE_MINUTES) {
          trackDataUpdated();
          lastMessage = Date.now();
        }

        const data = JSON.parse(ev.data) as SocketData;

        switch (data.type) {
          case "price_update":
            global.Sparta.registerUpdate(
              data.payload.code,
              data.payload.tenorName,
              parseFutureValue(data.payload.price, data.payload.priceType)
            );
            break;
          case "batch_update":
            Object.entries(data.payload).forEach(([key, { price, priceType }]) =>
              global.Sparta.registerUpdateByKey(key, parseFutureValue(price, priceType))
            );
            break;
          case "heart_beat":
          default:
            break;
        }

        global.Sparta.socketReceived();
      };

      socket.send(token);
    }
  };

  return socket;
};
