import { datadogRum } from "@datadog/browser-rum";
import { deserializeProductInfo, serializeProductInfo } from "functions/utils";

import { DATA_PROVIDER_PARAM_KEY, FUNCTIONS_NAMESPACE } from "./constants";

const computeKey = (worksheetId: string) => `migrated-${worksheetId}`;

const updateProductInfoIfNeeded = (productInfo: string) => {
  const { NAME, DATA_PROVIDER } = deserializeProductInfo(productInfo);

  if (DATA_PROVIDER || !NAME) return productInfo;

  const metadatas = Object.values(global.Sparta.getProductsByLabel(NAME));

  // Priority to DP_1
  const providerCode =
    metadatas.find(({ dataProvider }) => dataProvider === "DP_1")?.dataProvider || metadatas[0].dataProvider;

  if (!providerCode) return productInfo;

  return serializeProductInfo({ NAME, DATA_PROVIDER: providerCode });
};

const setSheetAsMigrated = async ({ worksheetId }: { worksheetId: string }) => {
  const key = computeKey(worksheetId);

  Office.context.document.settings.set(key, true);
};

const injectProviderInSheet = async ({ worksheetId }: { worksheetId: string }) => {
  const key = computeKey(worksheetId);
  const isAlreadyMigrated = Office.context.document.settings.get(key);

  if (isAlreadyMigrated) return;

  let updatedCells = 0;
  await Excel.run(async (context) => {
    const matchingRanges = context.workbook.worksheets
      .getItem(worksheetId)
      .findAllOrNullObject(`${FUNCTIONS_NAMESPACE}.GETPRODUCTMETADATA`, {
        matchCase: true,
      })
      .load("isNullObject");

    await context.sync();

    if (matchingRanges.isNullObject) return;

    matchingRanges.load("areas/items/formulas");

    await context.sync();

    matchingRanges.areas.items.forEach((matchingRange) => {
      matchingRange.formulas = matchingRange.formulas.map((row) =>
        row.map((formula) => {
          if (typeof formula === "string" && !formula.includes(DATA_PROVIDER_PARAM_KEY)) {
            const formulaContent = formula.match(/\".*\"/g);

            if (!formulaContent || !formulaContent.length) return formula;

            const { 1: productInfo } = formulaContent[0].replace(/\"/g, "").split(",");

            updatedCells++;
            return formula.replace(productInfo, updateProductInfoIfNeeded(productInfo));
          }

          return formula;
        })
      );
    });
  });

  if (updatedCells) {
    datadogRum.addAction("ObMigration", {
      updatedCells,
    });
  }

  setSheetAsMigrated({ worksheetId });
};

export const startOBMigration = () => {
  return Excel.run(async (context) => {
    const sheets = context.workbook.worksheets.load("items");
    const activeSheet = context.workbook.worksheets.getActiveWorksheet().load("id");

    await context.sync();

    injectProviderInSheet({ worksheetId: activeSheet.id });

    sheets.onAdded.remove(setSheetAsMigrated);
    sheets.onAdded.add(setSheetAsMigrated);

    sheets.items.forEach((sheet) => {
      sheet.onActivated.remove(injectProviderInSheet);
      sheet.onActivated.add(injectProviderInSheet);
    });

    return context.sync();
  });
};
