import { datadogRum } from "@datadog/browser-rum";
import { trackDataUpdated } from "services/liveCurves/segment";
import { CoreWebSocket } from "services/webSocket/CoreWebSocket";
import { AuthToken, PriceValue } from "services/webSocket/types";
import { LiveStatus } from "types";
import { getFrequencyValue } from "utils/helpers";
import { getSelectedFrequency } from "utils/localStorage";
import { getToken } from "utils/token";

import { Subject } from "./Subject";

const { REACT_APP_WS_CORE_URL } = process.env;

const ONE_SECOND = 1000;
const TWO_SECONDS = ONE_SECOND * 2;
const THIRTY_SECONDS = ONE_SECOND * 30;
const ONE_MINUTE = ONE_SECOND * 60;
const FIVE_MINUTES = ONE_MINUTE * 5;
const FIFTEEN_MINUTES = ONE_MINUTE * 15;

const LAST_TRACK_UPDATE_KEY = "lastTrackUpdate";
export const LATEST_VALUES_KEY = "LATEST_VALUES_2";

export class SpartaDataFeed {
  private authToken = new Subject<AuthToken>(getToken() ?? "");
  private frequency = new Subject(getSelectedFrequency());

  private updatesScheduler = new Subject(0);
  private updatesSchedulerInterval: NodeJS.Timer | undefined;

  private socket: CoreWebSocket | undefined;
  private priceValueSubjects = new Map<string, Subject<PriceValue>>();

  public liveStatus = new Subject<LiveStatus>("disconnected");

  private reconnectionTimeoutValue = ONE_SECOND;
  private reconnectionTimeout: NodeJS.Timeout | undefined;

  private pendingRequests = new Map<string, () => void>();

  private url: string;

  constructor(url: string) {
    this.url = url;

    this.subscribeToken();
    this.subscribeFrequency();

    setInterval(() => this.sendPendingRequests(), TWO_SECONDS);
    setInterval(() => this.saveLatestValues(), FIFTEEN_MINUTES);
  }

  // #region socket
  // TODO: Change to private when removing the old socket (excel_new_ws)
  public startSocket() {
    this.liveStatus.next("connecting");

    this.socket = new CoreWebSocket({
      url: `${REACT_APP_WS_CORE_URL}${this.url}`,
      onPrice: ({ product, price, tenorName }) => {
        SpartaDataFeed.trackDataUpdatedIfNeeded();

        const key = SpartaDataFeed.parseKey(product, tenorName);
        const subject = this.priceValueSubjects.get(key);

        if (this.frequency.getValue() === "live") subject?.next(price);
        else {
          this.updatesScheduler.subscribe(({ subscriptionId }) => {
            subject?.next(price);

            this.updatesScheduler.unsubscribe(subscriptionId);
          }, false);
        }
      },
      onAuthSuccess: () => {
        this.reconnectionTimeoutValue = ONE_SECOND;
        this.liveStatus.next("connected");
        this.restoreDataFeed();
      },
      onAuthError: () => this.liveStatus.next("disconnected"),
      onOpen: () => {
        this.socket?.sendAuth(this.authToken.getValue());
        this.socket?.ping();
      },
      onClose: (wasClean) => {
        this.liveStatus.next("disconnected");
        this.socket = undefined;

        if (!wasClean) {
          this.reconnect();
        }
      },
      onError: (error) => {
        datadogRum.addError("WebSocket error", { error });
      },
      onUnexpectedMessage: (message) => {
        datadogRum.addError("WebSocket message parsing error", { message: message });
      },
      onPong: () => {
        setTimeout(() => this.socket?.ping(), THIRTY_SECONDS);
      },
    });
  }

  // TODO: Remove with the old socket (excel_new_ws)
  public closeSocket() {
    this.socket?.close();
  }

  private reconnect() {
    clearTimeout(this.reconnectionTimeout);

    this.reconnectionTimeout = setTimeout(() => {
      this.startSocket();
    }, this.reconnectionTimeoutValue);

    this.reconnectionTimeoutValue = Math.min(this.reconnectionTimeoutValue * 2, THIRTY_SECONDS);
  }
  // #endregion

  // #region subject methods
  public getPriceSubject(product: string, tenorName: string): Subject<PriceValue> {
    const key = SpartaDataFeed.parseKey(product, tenorName);

    let subject = this.priceValueSubjects.get(key);

    if (!subject) {
      subject = this.subjectFactory(product, tenorName, SpartaDataFeed.getLatestValue(key));
    }

    return subject;
  }

  private subjectFactory(product: string, tenorName: string, priceValue: PriceValue): Subject<PriceValue> {
    const key = SpartaDataFeed.parseKey(product, tenorName);

    const subject = new Subject<PriceValue>(priceValue, {
      onStartObserving: () => {
        this.subscribeStream(product, tenorName);
      },
      onEndObserving: () => {
        this.unsubscribeStream(product, tenorName);
        this.priceValueSubjects.delete(key);
      },
    });

    this.priceValueSubjects.set(key, subject);

    return subject;
  }

  private sendStreamMessage(
    product: string,
    tenorName: string,
    streamType: keyof Pick<CoreWebSocket, "subscribe" | "unsubscribe">
  ) {
    const key = SpartaDataFeed.parseKey(product, tenorName);
    const streamFn = () => this.socket?.[streamType]({ type: "price", product, tenorName });

    if (this.socket?.canSendMessages) {
      streamFn();
      this.pendingRequests.delete(key);
    } else {
      this.pendingRequests.set(key, streamFn);
    }
  }

  private subscribeStream(product: string, tenorName: string) {
    this.sendStreamMessage(product, tenorName, "subscribe");
  }

  private unsubscribeStream(product: string, tenorName: string) {
    this.sendStreamMessage(product, tenorName, "unsubscribe");
  }

  private sendPendingRequests() {
    if (!this.socket?.canSendMessages) return;

    this.pendingRequests.forEach((fn) => fn());
    this.pendingRequests.clear();
  }

  private clearDataFeed() {
    this.priceValueSubjects.forEach((subject, key) => {
      if (!subject.observed) return;

      const { product, tenorName } = SpartaDataFeed.unparseKey(key);

      this.unsubscribeStream(product, tenorName);
    });
  }

  private restoreDataFeed() {
    this.priceValueSubjects.forEach((subject, key) => {
      if (!subject.observed) return;

      const { product, tenorName } = SpartaDataFeed.unparseKey(key);

      this.subscribeStream(product, tenorName);
    });
  }
  // #endregion

  // #region cache methods
  private static getLatestValues(): Record<string, PriceValue> {
    try {
      const values = window.localStorage.getItem(LATEST_VALUES_KEY);

      return values ? JSON.parse(values) : {};
    } catch (error) {
      datadogRum.addError("Failed to get latest values", { error });
      return {};
    }
  }

  private static getLatestValue(key: string): PriceValue {
    return SpartaDataFeed.getLatestValues()[key] ?? null;
  }

  private saveLatestValues() {
    try {
      const previousValues = SpartaDataFeed.getLatestValues();

      const values = Array.from(this.priceValueSubjects.entries())
        .filter(([, subscription]) => subscription.observed)
        .reduce<Record<string, PriceValue>>(
          (res, [key, subscription]) => ({
            ...res,
            [key]: subscription.getValue(),
          }),
          { ...previousValues }
        );

      window.localStorage.setItem(LATEST_VALUES_KEY, JSON.stringify(values));
    } catch (error) {
      datadogRum.addError("Failed to save latest values", { error });
    }
  }
  // #endregion

  // #region other methods
  public getObservedProducts() {
    return [
      ...new Set(
        [...this.priceValueSubjects.entries()].flatMap(([key, subscription]) =>
          subscription.observed ? SpartaDataFeed.unparseKey(key).product : []
        )
      ),
    ];
  }

  private subscribeToken() {
    this.authToken.subscribe(({ value: newToken }) => {
      if (!newToken) this.clearDataFeed();

      if (this.socket) this.socket.sendAuth(newToken);
      else if (this.frequency.getValue() !== "pause") this.startSocket();
    }, false);

    setInterval(() => this.authToken.next(getToken() ?? ""), TWO_SECONDS);
  }

  private subscribeFrequency() {
    this.frequency.subscribe(({ value: newFrequency, prevValue: oldFrequency }) => {
      if (newFrequency === "pause") {
        this.socket?.close();
      } else if ((oldFrequency === "pause" || oldFrequency === undefined) && global.Sparta.isNewWSEnabled()) {
        this.startSocket();
      }

      clearInterval(this.updatesSchedulerInterval);

      if (newFrequency !== "pause" && newFrequency !== "live") {
        const frequencyMs = getFrequencyValue(newFrequency, global.Sparta.user);

        this.updatesSchedulerInterval = setInterval(
          () => this.updatesScheduler.next(this.updatesScheduler.getValue() + 1),
          frequencyMs
        );
      }
    });

    setInterval(() => this.frequency.next(getSelectedFrequency()), TWO_SECONDS);
  }

  private static trackDataUpdatedIfNeeded() {
    const lastTrack = parseInt(localStorage.getItem(LAST_TRACK_UPDATE_KEY) ?? "");

    if (isNaN(lastTrack) || Date.now() - lastTrack >= FIVE_MINUTES) {
      trackDataUpdated();
      localStorage.setItem(LAST_TRACK_UPDATE_KEY, Date.now().toString());
    }
  }

  private static parseKey(product: string, tenorName: string) {
    return `${product}::${tenorName}`;
  }

  private static unparseKey(key: string) {
    const [product, tenorName] = key.split("::");

    return { product, tenorName };
  }
  // #endregion
}
