import {
  AuthToken,
  CoreSubscription,
  CoreWebSocketHooks,
  CoreWebSocketOptions,
  SendMessage,
  StreamMessage,
} from "./types";

export class CoreWebSocket {
  private instance: WebSocket;
  private _hooks: CoreWebSocketHooks;

  constructor({ url, ...hooks }: CoreWebSocketOptions) {
    this._hooks = hooks;

    this.instance = new WebSocket(url);

    this.instance.onopen = this.onOpen;
    this.instance.onmessage = this.onMessage;
    this.instance.onclose = this.onClose;
    this.instance.onerror = this.onError;
  }

  // #region getters
  private get status() {
    return this.instance.readyState;
  }

  get canSendMessages() {
    return this.status === WebSocket.OPEN;
  }
  // #endregion

  // #region basic methods
  private onOpen = () => {
    this._hooks.onOpen?.();
  };

  private onMessage = (event: MessageEvent) => {
    try {
      const message = JSON.parse(event.data) as StreamMessage;

      switch (message.type) {
        case "auth-success":
          return this._hooks.onAuthSuccess?.();
        case "auth-error":
          return this._hooks.onAuthError?.();
        case "pong":
          return this._hooks.onPong?.();
        case "price":
          return this._hooks.onPrice?.(message.payload);
        default:
          return this._hooks.onUnexpectedMessage?.(message);
      }
    } catch {
      this._hooks.onUnexpectedMessage?.(event.data);
    }
  };

  private onClose = (ev: CloseEvent) => {
    this._hooks.onClose?.(ev.wasClean);
  };

  private onError = (error: Event) => {
    this._hooks.onError?.(error);
  };

  private send = (message: SendMessage) => {
    if (!this.canSendMessages) return;

    this.instance.send(JSON.stringify(message));
  };

  public close = () => {
    this.instance.close();
  };
  // #endregion

  // #region send methods
  public subscribe = (payload: CoreSubscription) => {
    this.send({ type: "subscribe", payload });
  };

  public unsubscribe = (payload: CoreSubscription) => {
    this.send({ type: "unsubscribe", payload });
  };

  public ping = () => {
    this.send({ type: "ping", payload: { name: "health check" } });
  };

  public sendAuth = (authToken: AuthToken) => {
    this.send({ type: "auth", payload: authToken });
  };
  // #endregion
}
