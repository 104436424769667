import { updateRibbonByUser } from "auth/ribbonButtons";
import { applyConditionalFormatToAllSheets, recalculateWorkbook, requestPrices } from "commands/commands";
import { startOBMigration } from "commands/obMigration";
import { differenceInSeconds } from "date-fns";
import { enableUpdateButton } from "ribbon/utils";
import { futures, getProductsMetadata, livePrices } from "services";
import { UserMe } from "services/auth/types";
import { ProductMetadata, Provider } from "services/liveCurves/types";
import { Frequency, LiveStatus } from "types";
import { getFrequencyValue } from "utils/helpers";
import { getSelectedFrequency, setSelectedFrequency } from "utils/localStorage";
import { getFeatureFlagValue, isEntitlementEnabled } from "utils/user";

import { Subject } from "./Subject";

export const LATEST_VALUES_KEY = "LATEST_VALUES";
export const LAST_PATH_KEY = "LAST_PATH";
export const METADATAS_KEY = "METADATAS_2";

export class SpartaAPI {
  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  private _socket: WebSocket[] | undefined = undefined;
  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  private _lastSocketMessage: Date | undefined = undefined;

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  private _subscriptions = new Map<string, Subject<number | string | null>>();

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  private _queueProducts = new Set<string>();
  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  private timeout: NodeJS.Timeout | undefined = undefined;

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  private interval: NodeJS.Timer | undefined;

  public metadataSubject = new Subject<Record<string, ProductMetadata | undefined>>({});

  private _user: UserMe | undefined;
  revalidateUser: boolean;

  activeRoute = new Subject(localStorage.getItem(LAST_PATH_KEY) || "");
  isTaskPaneVisible = false;

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  private _updates = new Map<string, number | string | null>();

  private lastRecalculate: number | undefined;

  constructor(user: UserMe | undefined) {
    this.revalidateUser = !!user;

    this.initialize(user);
  }

  private async initialize(user: UserMe | undefined) {
    this.loadLatestValues();
    this.setUser(user);
  }

  public get user() {
    return this._user;
  }

  public isObEnabled() {
    return !!getFeatureFlagValue(this.user, "fe_excel_ob");
  }

  public isNewWSEnabled() {
    return !!getFeatureFlagValue(this.user, "excel_new_ws");
  }

  public async setUser(user: UserMe | undefined) {
    const prevUser = this._user;
    this._user = user;

    if (
      Boolean(this._user) !== Boolean(prevUser) ||
      (this._user && prevUser && this.isObEnabled() !== getFeatureFlagValue(prevUser, "fe_excel_ob"))
    ) {
      applyConditionalFormatToAllSheets();

      if (this._user) {
        const metadata = await getProductsMetadata();
        this.metadataSubject.next(metadata);

        if (global.Sparta.isObEnabled()) startOBMigration();
      } else {
        this.metadataSubject.next({});
      }
    }

    // TODO: Remove with the old socket (excel_new_ws)
    if (this.isNewWSEnabled() !== getFeatureFlagValue(prevUser, "excel_new_ws")) {
      if (this.isNewWSEnabled() && !!prevUser) {
        global.CurvesDataFeed.startSocket();
        global.FuturesDataFeed.startSocket();
      } else if (!this.isNewWSEnabled()) {
        global.CurvesDataFeed.closeSocket();
        global.FuturesDataFeed.closeSocket();
      }

      // More security to prevent any recalculation loop
      if (!this.lastRecalculate || Date.now() - this.lastRecalculate > 20_000) {
        this.lastRecalculate = Date.now();
        recalculateWorkbook(); // Needed to attach the formulas to the correct subscription
      }
    }

    this.updateSocketConnection();
    this.startUpdateInterval();
    return updateRibbonByUser(this._user, this.updateFrequency);
  }

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  public busForProduct(product: string, period: string, price?: number | string | null) {
    return this.busForKey(`${product}::${period}`, price, price === undefined);
  }

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  private busForKey(key: string, price: number | string | null | undefined, revalidate: boolean) {
    let bus = this._subscriptions.get(key);
    let alreadyCreated = true;

    if (!bus) {
      alreadyCreated = false;
      bus = new Subject(price ?? null);

      this._subscriptions.set(key, bus);
    } else if (price !== undefined) {
      bus.next(price);
    }

    if (revalidate && !alreadyCreated) {
      const [product] = key.split("::");

      this.addToQueue(product);
    }

    return bus;
  }

  public static computeProductLabel(name: ProductMetadata["shortName"], units: ProductMetadata["units"]) {
    return `${name} ${units.length ? units : ""}`.trim();
  }

  public static retrieveHistoricalLabels(metadata: ProductMetadata): string[] {
    const { shortName, units, alias } = metadata;

    const names = Array.isArray(alias) && alias.length > 0 ? Array.from(new Set([shortName, ...alias])) : [shortName];

    return names.map((n) => SpartaAPI.computeProductLabel(n, units));
  }

  public getProductsByLabel(
    label: string,
    metadatas?: Record<string, ProductMetadata | undefined>
  ): Record<string, ProductMetadata> {
    return Object.entries(metadatas ?? this.metadataSubject.getValue()).reduce((res, [code, metadata]) => {
      if (!metadata) return res;

      const labels = SpartaAPI.retrieveHistoricalLabels(metadata);

      if (!labels.includes(label)) return res;

      return {
        ...res,
        [code]: metadata,
      };
    }, {});
  }

  public static getProductCodeByLabelAndProvider(
    label: string,
    provider: Provider,
    metadata: Record<string, ProductMetadata | undefined>,
    isObEnabled = true
  ): string | undefined {
    for (const productCode in metadata) {
      const productMetadata = metadata[productCode];
      if (!productMetadata) continue;

      const labels = SpartaAPI.retrieveHistoricalLabels(productMetadata);
      const { dataProvider } = productMetadata;

      if (labels.includes(label) && (dataProvider === provider || !isObEnabled)) {
        return productCode;
      }
    }

    return undefined;
  }

  public getLabelByProductCode(productCode: string) {
    const metadata = this.metadataSubject.getValue()[productCode];

    if (!metadata) return "";

    return SpartaAPI.computeProductLabel(metadata.shortName, metadata.units);
  }

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  public isConnected() {
    return !!this._socket?.every((s) => s.readyState === WebSocket.OPEN);
  }

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  private loadLatestValues() {
    try {
      const rawData = window.localStorage.getItem(LATEST_VALUES_KEY);

      // Legacy to support old documents that use document.settings instead of localstorage
      let data: Record<string, number | null> = Office.context.document.settings.get(LATEST_VALUES_KEY) || {};

      if (rawData) {
        const localStorageData = JSON.parse(rawData);
        data = { ...data, ...localStorageData };
      }

      Object.entries(data).forEach(([key, value]) => this.busForKey(key, value, true));
    } catch (error) {
      return null;
    }
  }

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  public getObservedProducts() {
    return [
      ...new Set(
        [...this._subscriptions.entries()].flatMap(([key, subscription]) =>
          subscription.observed ? key.split("::")[0] : []
        )
      ),
    ].map((code) => this.getLabelByProductCode(code));
  }

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  public saveLatestValues() {
    try {
      const values = Array.from(this._subscriptions.entries())
        .filter(([, subscription]) => subscription.observed)
        .reduce<Record<string, number | string | null>>(
          (res, [key, subscription]) => ({
            ...res,
            [key]: subscription.getValue(),
          }),
          {}
        );

      window.localStorage.setItem(LATEST_VALUES_KEY, JSON.stringify(values));
    } catch (error) {
      console.error(error);
    }
  }

  public userNavigate(path: string) {
    this.activeRoute.next(path);

    if (path !== "redirect") {
      localStorage.setItem(LAST_PATH_KEY, path);
      return this.openTaskPane();
    }
  }

  public openTaskPane() {
    if (!this.isTaskPaneVisible) {
      this.isTaskPaneVisible = true;
      return Office.addin.showAsTaskpane();
    }
  }

  public navigateToLastPath() {
    const lastPath = localStorage.getItem(LAST_PATH_KEY) ?? "/";

    if (lastPath) {
      this.activeRoute.next(lastPath);
    }
  }

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  public updateSocketConnection() {
    if (
      this._user &&
      this.getSocketStatus() === "disconnected" &&
      this.updateFrequency !== "pause" &&
      !this.isNewWSEnabled()
    ) {
      this._socket?.forEach((s) => s.close());
      if (isEntitlementEnabled(this._user, "iceData")) {
        this._socket = [livePrices(), futures()];
      } else {
        this._socket = [livePrices()];
      }
    } else if (!this._user || this.updateFrequency === "pause" || this.isNewWSEnabled()) {
      this._socket?.forEach((s) => s.close());
      this._socket = undefined;
    }
  }

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  public socketReceived() {
    this._lastSocketMessage = new Date();
  }

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  public getSocketStatus(): LiveStatus {
    const statuses = this._socket?.map((s) => s.readyState);

    if (
      statuses?.every((s) => s === 1) &&
      (!navigator.onLine || !this._lastSocketMessage || differenceInSeconds(new Date(), this._lastSocketMessage) > 30)
    ) {
      return "connecting";
    }

    if (statuses?.some((s) => s === 0)) return "connecting";
    if (statuses?.every((s) => s === 1)) return "connected";
    return "disconnected";
  }

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  private addToQueue(product: string) {
    this._queueProducts.add(product);
    this.timeoutProducts();
  }

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  private timeoutProducts() {
    clearTimeout(this.timeout);

    if (this.isNewWSEnabled()) return;

    this.timeout = setTimeout(async () => {
      if (this._user && this._queueProducts.size && this.updateFrequency !== "pause") {
        const products = [...this._queueProducts];
        const metadatas = this.metadataSubject.getValue();
        requestPrices(products.map((code) => ({ code, type: metadatas[code]?.type || "Normal" })));
        this._queueProducts.clear();
      } else {
        this.timeoutProducts();
      }
    }, 5000);
  }

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  public registerUpdate(product: string, period: string, price: number | string | null) {
    this.registerUpdateByKey(`${product}::${period}`, price);
  }

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  public registerUpdateByKey(key: string, value: number | string | null) {
    if (this.updateFrequency === "live") {
      this.busForKey(key, value, false);
    } else {
      this._updates.set(key, value);
    }
  }

  public set updateFrequency(frequency: Frequency) {
    if (frequency !== this.updateFrequency) {
      const previous = this.updateFrequency;
      setSelectedFrequency(frequency);

      this.updateSocketConnection();
      this.startUpdateInterval();

      updateRibbonByUser(this._user, frequency, previous !== "pause");

      if (previous === "pause") {
        this.revalidatePrices();
      }
    }
  }

  public get updateFrequency() {
    return getSelectedFrequency();
  }

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  private startUpdateInterval() {
    clearInterval(this.interval);

    if (this.updateFrequency === "idle" || this.updateFrequency === "recommended") {
      const updateFn = () => {
        Array.from(this._updates.entries()).forEach(([key, value]) => this.busForKey(key, value, false));
      };

      this.interval = setInterval(updateFn, getFrequencyValue(this.updateFrequency, this.user));

      if (this.updateFrequency === "recommended") updateFn();
    }
  }

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  private async revalidatePrices() {
    const products = Array.from(this._subscriptions.entries()).flatMap(([key, subscription]) =>
      subscription.observed ? key.split("::")[0] : []
    );
    const metadatas = this.metadataSubject.getValue();

    if (products.length) {
      await requestPrices(products.map((code) => ({ code, type: metadatas[code]?.type || "Normal" })));
    }

    enableUpdateButton();
  }

  // #region Debug functions
  public dumpUser(): string {
    return JSON.stringify(this._user);
  }

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  public dumpSubscriptions(): string {
    return JSON.stringify({
      count: this._subscriptions.size,
      headValues: Array.from(this._subscriptions.keys()).slice(0, 150),
    });
  }

  /**
   * @deprecated Remove with the old socket (excel_new_ws)
   */
  public dumpQueue(): string {
    return JSON.stringify({ count: this._queueProducts.size, ...Object.fromEntries(this._queueProducts.entries()) });
  }
  // #endregion
}
