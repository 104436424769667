import { SpartaAPI } from "classes/SpartaAPI";
import {
  GET_METADATA_FUNCTION_NAME,
  GET_PERIOD_FUNCTION_NAME,
  OBSERVER_LIVECURVE_FUNCTION_NAME,
} from "functions/constants";
import { serializePeriod, serializeProductInfo } from "functions/utils";
import { getFuturePrices, getPrices } from "services";
import { PortfolioDetailDTO, Price, Product } from "services/liveCurves/types";
import { groupBy, parseFutureValue } from "utils/helpers";

import {
  DATA_PROVIDER_PARAM_KEY,
  DATA_PROVIDERS,
  FORMULA_REFER_ITSELF,
  FUNCTIONS_NAMESPACE,
  PERIOD_CODES,
  TOTAL_ROWS_WITH_PRODUCT_HEADER,
} from "./constants";
import { injectFormula } from "./utils";

export const recalculateWorkbook = () =>
  Excel.run(async (context) => {
    const sheets = context.workbook.worksheets.load("items");

    await context.sync();

    sheets.items.forEach((sheet) => {
      const ranges = sheet.findAllOrNullObject(FUNCTIONS_NAMESPACE, { matchCase: true });

      ranges.calculate();
    });
  });

/**
 * @deprecated Remove with the old socket (excel_new_ws)
 */
export const requestPrices = async (products: Pick<Price, "code" | "type">[]) => {
  if (global.Sparta.isNewWSEnabled()) return; // Not needed as the new WS will return the latest price when the subscription is created

  const groupedCodes = groupBy(products, "type");

  const { 0: prices, 1: futurePrices } = await Promise.all([
    getPrices(groupedCodes.Normal?.map(({ code }) => code)),
    getFuturePrices(groupedCodes.Future?.map(({ code }) => code)),
  ]);

  prices.forEach(({ code, tenorName, price }) => {
    global.Sparta.busForProduct(code, tenorName, price);
  });

  futurePrices.forEach(({ code, tenorName, value, priceType }) => {
    global.Sparta.busForProduct(code, tenorName, parseFutureValue(value, priceType));
  });
};

function cleanConditionalFormats(sheet: Excel.Worksheet) {
  // Takes the bottom right cell of the sheet (XFD1048576).
  // Due to performance limitations we can't check manually each CF, so we assume that all the CF applying that cell are from Sparta
  const range = sheet.getRange().getLastCell();

  range.conditionalFormats.clearAll();
}

const addConditionalFormats = (sheet: Excel.Worksheet) => {
  const range = sheet.getRange();

  DATA_PROVIDERS.forEach((provider) => {
    // Don't create the CF if the FF is disabled and the provider is different than the default one
    if (!global.Sparta.isObEnabled() && !!provider.code) return;

    let rule = "=";

    const ruleMatchBaseFormula = `FIND(".GETPRODUCTMETADATA(",${FORMULA_REFER_ITSELF}) > 0`;

    if (provider.code) {
      rule += `AND(${ruleMatchBaseFormula},FIND("${DATA_PROVIDER_PARAM_KEY}:${provider.code}",${FORMULA_REFER_ITSELF}) > 0)`;
    } else {
      rule += ruleMatchBaseFormula;
    }

    range.conditionalFormats.add(Excel.ConditionalFormatType.custom).custom.set({
      format: {
        font: {
          color: provider.color.font,
        },
        fill: {
          color: provider.color.fill,
        },
      },
      rule: {
        formula: rule,
      },
    });
  });

  range.conditionalFormats.add(Excel.ConditionalFormatType.custom).custom.set({
    format: {
      font: {
        color: "#FFFFFF",
      },
      fill: {
        color: "#3E3E4D",
      },
    },
    rule: {
      formula: `=FIND(".GETPERIOD(",${FORMULA_REFER_ITSELF}) > 0`,
    },
  });

  const cf = range.conditionalFormats.add(Excel.ConditionalFormatType.custom);
  const cfCustom = cf.customOrNullObject;

  cfCustom.set({
    format: {
      font: {
        color: "#555555",
      },
      fill: {
        color: "#D7D8D9",
      },
    },
    rule: {
      formula: `=FIND(".OBSERVELIVECURVE(",${FORMULA_REFER_ITSELF}) > 0`,
    },
  });

  (["top", "right", "bottom", "left"] as const).forEach((border) => {
    cfCustom.format.borders[border].color = "#D1D1D1";
    cfCustom.format.borders[border].style = "Continuous";
  });
};

const applyFormatToRange = async (
  originalRange: Excel.Range,
  includePeriodColumn: boolean,
  context: Excel.RequestContext
) => {
  originalRange.load(["columnIndex", "columnCount", "rowIndex", "rowCount"]);

  await context.sync();

  const { columnIndex, columnCount, rowIndex, rowCount } = originalRange;

  const horizontalHeaderRange = originalRange.worksheet.getRangeByIndexes(
    rowIndex,
    columnIndex + (includePeriodColumn ? 1 : 0),
    2,
    columnCount - (includePeriodColumn ? 1 : 0)
  );

  horizontalHeaderRange.format.columnWidth = 63.5;
  horizontalHeaderRange.format.horizontalAlignment = "Center";

  if (includePeriodColumn) {
    const verticalHeaderRange = originalRange.worksheet.getRangeByIndexes(rowIndex + 2, columnIndex, rowCount - 2, 1);

    verticalHeaderRange.format.horizontalAlignment = "Right";
  }
};

export const injectProducts = async (
  products: Pick<Product, "code" | "dataProvider" | "shortName" | "unit">[],
  includePeriodColumn = false
) => {
  const metadatas = global.Sparta.metadataSubject.getValue();

  await requestPrices(products.map(({ code }) => ({ code, type: metadatas[code]?.type || "Normal" })));

  return Excel.run(async (context) => {
    const originalRange = context.workbook.getSelectedRange().load("rowIndex");

    await context.sync();

    const { rowIndex } = originalRange;
    const columnRange = originalRange
      .getAbsoluteResizedRange(TOTAL_ROWS_WITH_PRODUCT_HEADER, products.length + (includePeriodColumn ? 1 : 0))
      .getEntireColumn()
      .insert("Right")
      .getCell(rowIndex, 0)
      .getAbsoluteResizedRange(TOTAL_ROWS_WITH_PRODUCT_HEADER, products.length + (includePeriodColumn ? 1 : 0));

    let values = [
      products.map(({ dataProvider, shortName, unit }) =>
        injectFormula(
          GET_METADATA_FUNCTION_NAME,
          "",
          serializeProductInfo({
            NAME: SpartaAPI.computeProductLabel(shortName, unit || ""),
            DATA_PROVIDER: dataProvider,
          }),
          "name"
        )
      ),
      products.map(({ dataProvider, shortName, unit }) =>
        injectFormula(
          GET_METADATA_FUNCTION_NAME,
          "",
          serializeProductInfo({
            NAME: SpartaAPI.computeProductLabel(shortName, unit || ""),
            DATA_PROVIDER: dataProvider,
          }),
          "units"
        )
      ),
      ...PERIOD_CODES.map((period) =>
        products.map(({ dataProvider, shortName, unit }) =>
          injectFormula(
            OBSERVER_LIVECURVE_FUNCTION_NAME,
            "",
            serializeProductInfo({
              NAME: SpartaAPI.computeProductLabel(shortName, unit || ""),
              DATA_PROVIDER: dataProvider,
            }),
            serializePeriod({ TENOR: period })
          )
        )
      ),
    ];

    if (includePeriodColumn) {
      values = values.map((row, idx) => [
        [0, 1].includes(idx)
          ? ""
          : injectFormula(GET_PERIOD_FUNCTION_NAME, "", serializePeriod({ TENOR: PERIOD_CODES[idx - 2] })),
        ...row,
      ]);
    }

    columnRange.values = values;

    await applyFormatToRange(columnRange, includePeriodColumn, context);

    return context.sync<void>();
  });
};

export const addPortfolioAsSheet = (portfolio: PortfolioDetailDTO, name: string) =>
  Excel.run(async (context) => {
    let sheets = context.workbook.worksheets;
    const newSheet = sheets.add(name);
    newSheet.load("name, position");

    try {
      await context.sync();
    } catch (e) {
      console.error("Invalid portfolio name: ", name);
      throw { id: portfolio.id, name };
    }

    const columnRange = newSheet.getRangeByIndexes(
      0,
      0,
      TOTAL_ROWS_WITH_PRODUCT_HEADER,
      portfolio.liveCurves.length + 1
    );

    columnRange.values = [
      [
        "",
        ...portfolio.liveCurves.map(({ dataProvider, shortName, units }) =>
          injectFormula(
            GET_METADATA_FUNCTION_NAME,
            "",
            serializeProductInfo({
              NAME: SpartaAPI.computeProductLabel(shortName, units || ""),
              DATA_PROVIDER: dataProvider,
            }),
            "name"
          )
        ),
      ],
      [
        "",
        ...portfolio.liveCurves.map(({ dataProvider, shortName, units }) =>
          injectFormula(
            GET_METADATA_FUNCTION_NAME,
            "",
            serializeProductInfo({
              NAME: SpartaAPI.computeProductLabel(shortName, units || ""),
              DATA_PROVIDER: dataProvider,
            }),
            "units"
          )
        ),
      ],
      ...PERIOD_CODES.map((period) => [
        injectFormula(GET_PERIOD_FUNCTION_NAME, "", serializePeriod({ TENOR: period })),
        ...portfolio.liveCurves.map(({ dataProvider, shortName, units }) =>
          injectFormula(
            OBSERVER_LIVECURVE_FUNCTION_NAME,
            "",
            serializeProductInfo({
              NAME: SpartaAPI.computeProductLabel(shortName, units || ""),
              DATA_PROVIDER: dataProvider,
            }),
            serializePeriod({ TENOR: period })
          )
        ),
      ]),
    ];

    await applyFormatToRange(columnRange, true, context);

    return context.sync();
  });

export const applyConditionalFormatToAllSheets = () =>
  Excel.run(async (context) => {
    const sheets = context.workbook.worksheets.load("items");

    await context.sync();

    sheets.items.forEach((sheet) => {
      cleanConditionalFormats(sheet);
      addConditionalFormats(sheet);
    });

    sheets.onAdded.add(async ({ worksheetId }) => {
      await Excel.run((context2) => {
        addConditionalFormats(context2.workbook.worksheets.getItem(worksheetId));

        return context2.sync();
      });
    });

    return context.sync();
  });
