import { SpartaAPI } from "classes/SpartaAPI";
import { DATA_PROVIDERS } from "commands/constants";
import { format, parseISO } from "date-fns";
import { ProductMetadata, Provider } from "services/liveCurves/types";
import { Nullable } from "types";
import { getEntries, getNumberFormatByDecimalPlaces, getTenorNameByCode } from "utils/helpers";

type KeyValue<Key extends string = string, Value = string | undefined> = Record<Key, Value>;

type PRODUCT_INFO_KEYS = "CODE" | "NAME" | "DATA_PROVIDER";
type PERIOD_KEYS = "TENOR" | "DATE";

const KEYVALUE_SEPARATOR = ":";
const PAIR_SEPARATOR = "|";

type SerializeFunction<Key extends string = string> = (keyValues: Partial<KeyValue<Key, Nullable<string>>>) => string;

export const serializeParam: SerializeFunction = (keyValues) =>
  Object.entries(keyValues)
    .filter(({ 1: value }) => !!value)
    .map(([key, value]) => `${key}${KEYVALUE_SEPARATOR}${value}`)
    .join(PAIR_SEPARATOR);

export const deserializeParam = <Key extends string = string>(param: string): KeyValue<Key> =>
  param.split(PAIR_SEPARATOR).reduce((res, keyValue) => {
    const [key, value] = keyValue.split(KEYVALUE_SEPARATOR);

    return {
      ...res,
      [key]: value,
    };
  }, {} as KeyValue<Key>);

export const serializeProductInfo: SerializeFunction<PRODUCT_INFO_KEYS> = (keyValues) => serializeParam(keyValues);

export const getParam = <Key extends string>(args: string, param: Key): string | undefined => {
  const params = deserializeParam<Key>(args);

  return params[param];
};

export const deserializeProductInfo = (productInfo: string) => deserializeParam<PRODUCT_INFO_KEYS>(productInfo);

export const getProductCode = (
  productInfo: string,
  metadata: Record<string, ProductMetadata | undefined>
): string | undefined => {
  const { CODE, DATA_PROVIDER, NAME } = deserializeProductInfo(productInfo);

  if (CODE) return CODE;

  if (!NAME) return undefined;

  const providerCode = parseDataProvider(DATA_PROVIDER);

  return SpartaAPI.getProductCodeByLabelAndProvider(NAME, providerCode, metadata, global.Sparta.isObEnabled());
};

export const serializePeriod: SerializeFunction<PERIOD_KEYS> = (keyValues) => serializeParam(keyValues);

const computePeriodValue = (key: PERIOD_KEYS, value: string | undefined): string | undefined => {
  if (!value) {
    return undefined;
  }

  switch (key) {
    case "TENOR":
      return getTenorNameByCode(value);
    case "DATE":
      return value;
    default:
      return undefined;
  }
};

export const deserializePeriod = (period: string): [PERIOD_KEYS, string | undefined] => {
  const keyValues = deserializeParam<PERIOD_KEYS>(period);

  const { DATE, TENOR } = getEntries(keyValues).reduce(
    (res, [key, value]) => ({ ...res, [key]: computePeriodValue(key, value) }),
    {} as KeyValue<PERIOD_KEYS>
  );

  if (DATE) return ["DATE", DATE];

  return ["TENOR", TENOR];
};

const parseDataProvider = (dataProvider: string | undefined): Provider => {
  const providerCode = DATA_PROVIDERS.find(({ code }) => code === dataProvider)?.code;

  return providerCode ?? null;
};

export const stringCell = (basicValue = ""): Excel.StringCellValue => ({
  type: "String",
  basicValue,
});

export const dateTimeCell = (basicValue = ""): Excel.StringCellValue =>
  stringCell(format(parseISO(basicValue), "dd MMM yyyy - HH:mm:ss"));

export const numberCell = (basicValue: number, decimalPlaces = 0): Excel.FormattedNumberCellValue => ({
  type: "FormattedNumber",
  basicValue,
  numberFormat: getNumberFormatByDecimalPlaces(decimalPlaces),
});
